import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3bb59992 = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _0e532012 = () => interopDefault(import('../pages/customers/index.vue' /* webpackChunkName: "pages/customers/index" */))
const _8192c76e = () => interopDefault(import('../pages/customers/index/create.vue' /* webpackChunkName: "pages/customers/index/create" */))
const _85af4d00 = () => interopDefault(import('../pages/customers/index/_id/index.vue' /* webpackChunkName: "pages/customers/index/_id/index" */))
const _e3bbcad4 = () => interopDefault(import('../pages/employee-schedules/index.vue' /* webpackChunkName: "pages/employee-schedules/index" */))
const _8e27517c = () => interopDefault(import('../pages/employees/index.vue' /* webpackChunkName: "pages/employees/index" */))
const _fd4c21ce = () => interopDefault(import('../pages/employees/index/create.vue' /* webpackChunkName: "pages/employees/index/create" */))
const _6c6b56a0 = () => interopDefault(import('../pages/employees/index/_id/index.vue' /* webpackChunkName: "pages/employees/index/_id/index" */))
const _e1f274e4 = () => interopDefault(import('../pages/location-select.vue' /* webpackChunkName: "pages/location-select" */))
const _519d83db = () => interopDefault(import('../pages/locations/index.vue' /* webpackChunkName: "pages/locations/index" */))
const _8e59d740 = () => interopDefault(import('../pages/locations/index/create.vue' /* webpackChunkName: "pages/locations/index/create" */))
const _44a35609 = () => interopDefault(import('../pages/locations/index/_id/index.vue' /* webpackChunkName: "pages/locations/index/_id/index" */))
const _b57d2fba = () => interopDefault(import('../pages/locations/index/_id/edit.vue' /* webpackChunkName: "pages/locations/index/_id/edit" */))
const _6fc40a3a = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _3cf96343 = () => interopDefault(import('../pages/prices/index.vue' /* webpackChunkName: "pages/prices/index" */))
const _a245d91e = () => interopDefault(import('../pages/prices/index/_id/index.vue' /* webpackChunkName: "pages/prices/index/_id/index" */))
const _b8b836a4 = () => interopDefault(import('../pages/prices/index/_type/create.vue' /* webpackChunkName: "pages/prices/index/_type/create" */))
const _6fe4f052 = () => interopDefault(import('../pages/printers/index.vue' /* webpackChunkName: "pages/printers/index" */))
const _d573f7ee = () => interopDefault(import('../pages/printers/index/create.vue' /* webpackChunkName: "pages/printers/index/create" */))
const _27e2d9c0 = () => interopDefault(import('../pages/printers/index/_id/index.vue' /* webpackChunkName: "pages/printers/index/_id/index" */))
const _69e3ed08 = () => interopDefault(import('../pages/reports/index.vue' /* webpackChunkName: "pages/reports/index" */))
const _26c6066a = () => interopDefault(import('../pages/reports/index/_id/index.vue' /* webpackChunkName: "pages/reports/index/_id/index" */))
const _6c6352d7 = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _5a31b1e4 = () => interopDefault(import('../pages/services/index/create.vue' /* webpackChunkName: "pages/services/index/create" */))
const _061f9a05 = () => interopDefault(import('../pages/services/index/_id/index.vue' /* webpackChunkName: "pages/services/index/_id/index" */))
const _37c859d6 = () => interopDefault(import('../pages/subscription/index.vue' /* webpackChunkName: "pages/subscription/index" */))
const _b8f4c3f6 = () => interopDefault(import('../pages/subscription/index/create.vue' /* webpackChunkName: "pages/subscription/index/create" */))
const _4cc390f1 = () => interopDefault(import('../pages/terminals.vue' /* webpackChunkName: "pages/terminals" */))
const _3ea3b18c = () => interopDefault(import('../pages/timesheets/index.vue' /* webpackChunkName: "pages/timesheets/index" */))
const _3638e18c = () => interopDefault(import('../pages/employee-schedules/index2.vue' /* webpackChunkName: "pages/employee-schedules/index2" */))
const _54174c5f = () => interopDefault(import('../pages/pos/new.vue' /* webpackChunkName: "pages/pos/new" */))
const _03135068 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5c67adbf = () => interopDefault(import('../pages/pos/_customer/index.vue' /* webpackChunkName: "pages/pos/_customer/index" */))
const _aff81a4e = () => interopDefault(import('../pages/terminals' /* webpackChunkName: "" */))
const _1fff2bd5 = () => interopDefault(import('../pages/pos/_customer/pay.vue' /* webpackChunkName: "pages/pos/_customer/pay" */))
const _7269ba4c = () => interopDefault(import('../pages/pos/_customer/pay/payment.vue' /* webpackChunkName: "pages/pos/_customer/pay/payment" */))
const _17d79bd0 = () => interopDefault(import('../pages/pos/_customer/payments.vue' /* webpackChunkName: "pages/pos/_customer/payments" */))
const _65be0342 = () => interopDefault(import('../pages/pos/_customer/pickup.vue' /* webpackChunkName: "pages/pos/_customer/pickup" */))
const _32a62fd8 = () => interopDefault(import('../pages/pos/_customer/tickets.vue' /* webpackChunkName: "pages/pos/_customer/tickets" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/change-password",
    component: _3bb59992,
    name: "change-password"
  }, {
    path: "/customers",
    component: _0e532012,
    name: "customers",
    children: [{
      path: "create",
      component: _8192c76e,
      name: "customers-index-create"
    }, {
      path: ":id",
      component: _85af4d00,
      name: "customers-index-id"
    }]
  }, {
    path: "/employee-schedules",
    component: _e3bbcad4,
    name: "employee-schedules"
  }, {
    path: "/employees",
    component: _8e27517c,
    name: "employees",
    children: [{
      path: "create",
      component: _fd4c21ce,
      name: "employees-index-create"
    }, {
      path: ":id",
      component: _6c6b56a0,
      name: "employees-index-id"
    }]
  }, {
    path: "/location-select",
    component: _e1f274e4,
    name: "location-select"
  }, {
    path: "/locations",
    component: _519d83db,
    name: "locations",
    children: [{
      path: "create",
      component: _8e59d740,
      name: "locations-index-create"
    }, {
      path: ":id",
      component: _44a35609,
      name: "locations-index-id"
    }, {
      path: ":id/edit",
      component: _b57d2fba,
      name: "locations-index-id-edit"
    }]
  }, {
    path: "/login",
    component: _6fc40a3a,
    name: "login"
  }, {
    path: "/prices",
    component: _3cf96343,
    name: "prices",
    children: [{
      path: ":id",
      component: _a245d91e,
      name: "prices-index-id"
    }, {
      path: ":type/create",
      component: _b8b836a4,
      name: "prices-index-type-create"
    }]
  }, {
    path: "/printers",
    component: _6fe4f052,
    name: "printers",
    children: [{
      path: "create",
      component: _d573f7ee,
      name: "printers-index-create"
    }, {
      path: ":id",
      component: _27e2d9c0,
      name: "printers-index-id"
    }]
  }, {
    path: "/reports",
    component: _69e3ed08,
    name: "reports",
    children: [{
      path: ":id",
      component: _26c6066a,
      name: "reports-index-id"
    }]
  }, {
    path: "/services",
    component: _6c6352d7,
    name: "services",
    children: [{
      path: "create",
      component: _5a31b1e4,
      name: "services-index-create"
    }, {
      path: ":id",
      component: _061f9a05,
      name: "services-index-id"
    }]
  }, {
    path: "/subscription",
    component: _37c859d6,
    name: "subscription",
    children: [{
      path: "create",
      component: _b8f4c3f6,
      name: "subscription-index-create"
    }]
  }, {
    path: "/terminals",
    component: _4cc390f1,
    name: "terminals"
  }, {
    path: "/timesheets",
    component: _3ea3b18c,
    name: "timesheets"
  }, {
    path: "/employee-schedules/index2",
    component: _3638e18c,
    name: "employee-schedules-index2"
  }, {
    path: "/pos/new",
    component: _54174c5f,
    name: "pos-new"
  }, {
    path: "/",
    component: _03135068,
    name: "index"
  }, {
    path: "/pos/:customer",
    component: _5c67adbf,
    name: "pos-customer"
  }, {
    path: "/terminals/:slug",
    component: _aff81a4e,
    name: "terminals-slug"
  }, {
    path: "/pos/:customer?/pay",
    component: _1fff2bd5,
    name: "pos-customer-pay",
    children: [{
      path: "payment",
      component: _7269ba4c,
      name: "pos-customer-pay-payment"
    }]
  }, {
    path: "/pos/:customer?/payments",
    component: _17d79bd0,
    name: "pos-customer-payments"
  }, {
    path: "/pos/:customer?/pickup",
    component: _65be0342,
    name: "pos-customer-pickup"
  }, {
    path: "/pos/:customer?/tickets",
    component: _32a62fd8,
    name: "pos-customer-tickets"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
