export default function (state) {
  const { route, store } = state

  const isLoggedIn = store.state.auth.loggedIn

  const appURL = `${process.env.NUXT_APP_URL}`

  const hasSubdomainApp = window.location.hostname.includes('app.')
  const { token } = route.query

  if (token && hasSubdomainApp && route.path === '/login') {
    return
  }

  if (!hasSubdomainApp && route.path === '/location-select') {
    window.location.href = `${appURL}/location-select`
    return
  }

  if (hasSubdomainApp && route.path === '/') {
    window.location.href = process.env.NUXT_LANDING_URL
    return
  }

  if (!hasSubdomainApp && route.path === '/login') {
    window.location.href = `${appURL}/login`
  }

  const publicRoutes = ['/login', '/']

  if (isLoggedIn && hasSubdomainApp && publicRoutes.includes(route.path)) {
    window.location.href = `${appURL}/location-select`
  }
}
